import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {

  // const logoname = "<Shekhar/>";

  return (
    <footer className='footer'>
      <div className="main-footer">
        <div className="box1">
          <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
          <li><NavLink to="/about" className={() => `navlink`}>About Me</NavLink></li>
          <li><NavLink to="/projects" className={() => `navlink`}>Projects</NavLink></li>
          <li><NavLink to="/resume" className={() => `navlink`}>My Resume</NavLink></li>
          <li><NavLink to="/contact" className={() => `navlink`}>Contact Me</NavLink></li>
        </div>
      </div>
      <div className="bottom-footer">
        <h3>Designed By Himali Chavan</h3>
      </div>
    </footer>
  )
}

export default Footer
