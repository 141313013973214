import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Header.css'


function Header() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const logoname = "< Himali />";

    return (
        <header className='header'>
            <div className={isMenuOpen ? "mobileMenu open" : "close"}>
                <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
                <li><NavLink to="/about" className="navlink" onClick={toggleMenu}>About Me</NavLink></li>
                <li><NavLink to="/projects" className="navlink" onClick={toggleMenu}>Projects</NavLink></li>
                <li><NavLink to="/resume" className="navlink" onClick={toggleMenu}>My Resume</NavLink></li>
                <li><NavLink to="/contact" className="navlink" onClick={toggleMenu}>Contact Me</NavLink></li>
                <button><Link to="https://wa.link/74ryod" >WhatsApp Me</Link></button>
                <button><Link to="tel:+91 82084 27525" >Call Now</Link></button>
            </div>
            <div className="center-navbar">
                <h1>{logoname}</h1>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
            </div>
            <div className="left-navbar">
                <ul >
                    <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
                    <li><NavLink to="/about" className={() => `navlink`}>About Me</NavLink></li>
                    <li><NavLink to="/projects" className={() => `navlink`}>Projects</NavLink></li>
                    <li><NavLink to="/resume" className={() => `navlink`}>My Resume</NavLink></li>
                    <li><NavLink to="/contact" className={() => `navlink`}>Contact Me</NavLink></li>
                </ul>
            </div>

            <div className="right-navbar">
                <button><Link to="https://wa.link/74ryod" >WhatsApp Me</Link></button>
                <button><Link to="tel:+91 82084 27525" >Call Now</Link></button>
            </div>
        </header>
    )
}

export default Header
