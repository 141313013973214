import React from 'react'
import Underline from '../Images/line.png'
import Underline2 from '../Images/underline.png'
import himu from '../Images/himali.jpg'

const Resume = () => {
  return (
    <div className="projects" id='projects'>
            <div className="head">
                <h2>My Resume</h2>
                <img src={Underline2} className='line' alt="" />
            </div>
            <div className="resumebox">
                <img src={himu} alt="" />
                <div className="box-content">
                    <h3>Himali Chavan</h3>
                    <p>A modern restaurant website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                    <button>View Resume</button>
                </div>
            </div>
        </div>
  )
}

export default Resume