import React from 'react'

import Underline from '../Images/line.png'
import Underline2 from '../Images/underline.png'

const Contact = () => {
    return (
        <div className="contact-container" id='contact'>
            {/* <div className="contactbox1">
                    <img src={ContactImage} alt="" />
                </div> */}
            <div className="contactbox2">
                <h2>Send Me a Message</h2>
                <img src={Underline} className='line' alt="" />
                <form action="" className='form'>
                    <input type="text" placeholder='Your Name' />
                    <input type="email" name="" id="" placeholder='Your Email' />
                    <input type="tel" name="" id="" placeholder='Your Contact Number' />
                    <textarea name="" id="" rows={3} placeholder='Message For Me :)'></textarea>
                </form>
            </div>
        </div>
    )
}

export default Contact