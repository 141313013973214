import React from 'react'

import Underline from '../Images/line.png'
import Underline2 from '../Images/underline.png'
import Project1 from '../Images/project1.png'
import Project2 from '../Images/project2.png'
import Project3 from '../Images/project3.png'
import Project4 from '../Images/project4.png'
import Project5 from '../Images/project5.png'
import Project6 from '../Images/project6.png'
import Project7 from '../Images/project7.png'
import Project8 from '../Images/project8.png'
import Project10 from '../Images/Project10.jpeg'

const Projects = () => {
    return (
        <div className="projects" id='projects'>
            <div className="head">
                <h2>My Projects</h2>
                <img src={Underline2} className='line' alt="" />
            </div>
            <div className="box">
                <img src={Project1} alt="" />
                <div className="box-content">
                    <h3>Restaurant Website</h3>
                    <p>A modern restaurant website using React.js, featuring dynamic menus, responsive design, and seamless navigation</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project2} alt="" />
                <div className="box-content">
                    <h3>Cafe Website</h3>
                    <p>A responsive café website using React.js, with interactive menus and a visually appealing design.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project3} alt="" />
                <div className="box-content">
                    <h3>News Website</h3>
                    <p>A news website using API fetching, displaying real-time articles with a clean, responsive layout."</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project4} alt="" />
                <div className="box-content">
                    <h3>Joke Generator</h3>
                    <p>A Joke Generator using API fetching, delivering random jokes with a simple, user-friendly interface.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project5} alt="" />
                <div className="box-content">
                    <h3>Weather App</h3>
                    <p>A weather app using API fetching, providing real-time forecasts with a clean, intuitive design.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project6} alt="" />
                <div className="box-content">
                    <h3>Quotes Generator</h3>
                    <p>A Quotes Generator using API fetching, displaying random inspirational quotes with a minimalist interface.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project7} alt="" />
                <div className="box-content">
                    <h3>Rock Paper Scissor</h3>
                    <p>A Rock Paper Scissors app using vanilla JavaScript, featuring basic gameplay and intuitive UI.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project8} alt="" />
                <div className="box-content">
                    <h3>Builder Landing Page</h3>
                    <p>Designed a builder landing page with a professional layout, showcasing services and client testimonials effectively.</p>
                    <button>View</button>
                </div>
            </div>
            <div className="box">
                <img src={Project10} alt="" />
                <div className="box-content">
                    <h3>Blog App</h3>
                    <p>Developed a full-stack blog app using Node.js and Express, featuring CRUD operations and user authentication.</p>
                    <button>View</button>
                </div>
            </div>

        </div>
    )
}

export default Projects