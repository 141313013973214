import React from 'react'
import '../Pages/Home/Home.css'

import Underline from '../Images/line.png'
import Underline2 from '../Images/underline.png'

import JS from '../Images/JS logo.png'
import CSS from '../Images/css.png'
import HTML from '../Images/html.png'
import ReactLogo from '../Images/reactjs.png'
import Node from '../Images/nodejs.png'
import Express from '../Images/express.png'
import MongoDB from '../Images/mongo.png'
import MySql from '../Images/mysql.png'
import Postman from '../Images/postman.png'
import Git from '../Images/git.png'

const About = () => {
  return (
    <aside className='about' id='about'>
                <h2> About Me</h2>
                <img src={Underline} className='line' alt="" />
                <p>
                I’m Himali Chavan, and I’ve completed my BCA and MCA with an 8.4 CGPA. My academic background has provided me with a solid foundation in computer science and advanced programming skills.
                </p>
                <p>I have a deep passion for making websites, which is evident in my diverse project portfolio. I love creating user-friendly, modern websites that are both visually appealing and functional. I’m always exploring new technologies and trends to enhance my web development skills and deliver the best possible results.</p>
                <div className="skills">
                    <div className="box"><img src={HTML} alt="" /></div>
                    <div className="box"><img src={JS} alt="" /></div>
                    <div className="box"><img src={CSS} alt="" /></div>
                    <div className="box"><img src={ReactLogo} alt="" /></div>
                    <div className="box"><img src={Node} alt="" /></div>
                    <div className="box"><img src={Express} alt="" /></div>
                    <div className="box"><img src={MongoDB} alt="" /></div>
                    <div className="box"><img src={MySql} alt="" /></div>
                    <div className="box"><img src={Postman} alt="" /></div>
                    <div className="box"><img src={Git} alt="" /></div>
                </div>
            </aside>
  )
}

export default About