import React from 'react'
import './Home.css'
import Underline from '../../Images/line.png'
import Underline2 from '../../Images/underline.png'
import homeImage from '../../Images/himali.jpg'
// import ContactImage from '../../Images/himaliContact.jpg'

import JS from '../../Images/JS logo.png'
import CSS from '../../Images/css.png'
import HTML from '../../Images/html.png'
import ReactLogo from '../../Images/reactjs.png'
import Node from '../../Images/nodejs.png'
import Express from '../../Images/express.png'
import MongoDB from '../../Images/mongo.png'
import MySql from '../../Images/mysql.png'
import Postman from '../../Images/postman.png'
import Git from '../../Images/git.png'

import Project1 from '../../Images/project1.png'
import Project2 from '../../Images/project2.png'
import Project3 from '../../Images/project3.png'
import Project4 from '../../Images/project4.png'
import Project5 from '../../Images/project5.png'
import Project6 from '../../Images/project6.png'
import Project7 from '../../Images/project7.png'
import Project8 from '../../Images/project8.png'
import Project10 from '../../Images/Project10.jpeg'







function Home() {
    return (
        <>
            <main>
                <div className="home-container">
                    <div className="homebox2">
                        <img src={homeImage} alt="" />
                    </div>
                    <div className="homebox1">
                        <h2>Hii, I'm</h2>
                        <img src={Underline2} alt="" />
                        <h1>Himali Chavan</h1>
                        
                        <p>I love making the things that
                            help others do their thing. Let's Discuss , Design and
                            Digitalize your thoughts.
                        </p>
                        <button>View Resume</button>
                    </div>
                    <div className="homebox3">
                        <div className="socials">
                            <i class="fa-brands fa-facebook"></i>
                            <i class="fa-brands fa-instagram"></i>
                            <i class="fa-brands fa-x-twitter"></i>
                            <i class="fa-brands fa-youtube"></i>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Home
